import { Popover, Transition } from '@headlessui/react';
import { router, usePage } from '@inertiajs/react';
import clsx from 'clsx';
import React, { Fragment } from 'react';
import { LowImpactButton } from '@/components/button/LowImpactButton';
import { Icon } from '@/components/icon/Icon';
import { Logo } from '@/components/logo/Logo';
import { NavGlobal } from '@/components/navigation/NavGlobal';
import { ActionTextBaseEmphasis } from '@/components/typography/actions/ActionText';
import { H1, H6 } from '@/components/typography/headings/Heading';
import { Breadcrumbs } from '@/components/header/Breadcrumbs';
import { useTheme } from '@/hooks/useTheme';
import { HighImpactBrightBadge } from '@/components/badge/Badge';
import { SubtleButton } from '@/components/button/SubtleButton';

interface Props {
  className?: string;
  area?: string;
  unread_notifications_count?: number;
}

export function HeaderGlobal({ className, area }: Props) {
  const page: any = usePage();
  const party = page.props.auth ? page.props.auth.party : { first_name: '' };
  const { backgroundColor, textColor, onDark } = useTheme();

  return (
    <Popover
      id="headerGlobal"
      className={clsx('max-h-full w-full overflow-hidden', backgroundColor, textColor, className)}
    >
      {({ open }) => (
        <>
          <div
            className={clsx(
              'relative flex h-20 justify-between px-8 py-2 shadow-bottom-80 md:shadow-none',
              backgroundColor
            )}
          >
            <div className="flex items-center">
              <Logo onDark={onDark} asLink className="my-auto h-14 w-11" />
              <Breadcrumbs className="my-auto" area={area} isOpen={open} />
            </div>

            {page.props.auth && (
              <div className="flex items-center hover:cursor-pointer">
                <Popover.Button
                  className={clsx('relative my-auto h-fit focus:outline-none', {
                    'pointer-events-none': open // Hack because we're rendering two Popover.Button components
                  })}
                >
                  <Icon
                    icon="menu-dashboard"
                    theme={onDark ? 'dark' : 'light'}
                    className={clsx(
                      'h-7 w-7 duration-100',
                      textColor,
                      open && 'hidden -rotate-45 duration-100 md:block'
                    )}
                  />
                  <Icon
                    icon="cross"
                    className={clsx(
                      'block h-7 w-7 md:hidden',
                      textColor,
                      open ? 'block md:hidden' : 'hidden'
                    )}
                  />
                  {page.props.unread_notifications_count ? (
                    <HighImpactBrightBadge
                      className="absolute -right-4 -top-3 outline outline-4 outline-black"
                      type="circle"
                      label={page.props.unread_notifications_count}
                    />
                  ) : null}
                </Popover.Button>
              </div>
            )}
          </div>
          {page.props.auth && (
            <Transition
              as={Fragment}
              enter="transition ease-out duration-200"
              enterFrom="opacity-0 translate-y-[-100%]"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-[-100%]"
            >
              <Popover.Panel className="absolute z-60 max-h-full w-full flex-none justify-between overflow-y-scroll bg-black pb-12 pl-6 pr-8 pt-6 text-white md:flex md:overflow-auto">
                <div className="py-16 md:hidden">
                  <H6>Central</H6>
                  <H1 className="mb-16 pt-3">Hi, {party.first_name}</H1>
                  <LowImpactButton
                    rounding="square"
                    className="mb-16 w-full md:hidden"
                    label={
                      <span className="flex gap-2">
                        {page.props.unread_notifications_count > 0 ? (
                          <HighImpactBrightBadge
                            type="medium"
                            label={page.props.unread_notifications_count}
                          />
                        ) : null}
                        <ActionTextBaseEmphasis>Inbox</ActionTextBaseEmphasis>
                      </span>
                    }
                    onClick={() => router.get(route('notification.index'))}
                  />
                </div>
                <NavGlobal className="gap-2" />
                <div className="hidden flex-col p-2 md:flex">
                  <H1>Hi, {party.first_name}</H1>
                  <div className="mt-4 flex items-center justify-end">
                    <SubtleButton
                      size="sm"
                      rounding="square"
                      label={
                        <span className="flex gap-2">
                          {page.props.unread_notifications_count > 0 ? (
                            <HighImpactBrightBadge
                              type="large"
                              label={page.props.unread_notifications_count}
                            />
                          ) : null}
                          <ActionTextBaseEmphasis>Inbox</ActionTextBaseEmphasis>
                        </span>
                      }
                      onClick={() => router.get(route('notification.index'))}
                    />
                  </div>
                  <LowImpactButton
                    rounding="square"
                    size="sm"
                    className="ml-auto mt-auto"
                    label="Log out"
                    onClick={() => router.post('/logout')}
                  />
                </div>
                <LowImpactButton
                  rounding="square"
                  className="my-16 w-full md:hidden"
                  label="Log out"
                  onClick={() => router.post('/logout')}
                />
              </Popover.Panel>
            </Transition>
          )}
        </>
      )}
    </Popover>
  );
}
