import clsx from 'clsx';
import React from 'react';

export type HeadingVariant = 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';

interface HeadingProps {
  children: React.ReactNode;
  className?: string;
}

interface Props extends HeadingProps {
  variant: HeadingVariant;
}

export const H1 = (props: HeadingProps) => <Heading variant="h1" {...props} />;
export const H2 = (props: HeadingProps) => <Heading variant="h2" {...props} />;
export const H3 = (props: HeadingProps) => <Heading variant="h3" {...props} />;
export const H4 = (props: HeadingProps) => <Heading variant="h4" {...props} />;
export const H5 = (props: HeadingProps) => <Heading variant="h5" {...props} />;
export const H6 = (props: HeadingProps) => <Heading variant="h6" {...props} />;

export function Heading({ variant, children, className, ...props }: Props) {
  const Component = variant;

  return (
    <Component
      className={clsx(
        'font-poppins',
        variant === 'h1' && 'text-2xl font-semibold ',
        variant === 'h2' && 'text-xl font-semibold',
        variant === 'h3' && 'text-lg font-semibold tracking-wide',
        variant === 'h4' && 'text-base font-semibold uppercase leading-6 tracking-wide',
        variant === 'h5' && 'text-sm font-semibold uppercase tracking-wide',
        variant === 'h6' && 'text-sm font-semibold tracking-wide',
        className
      )}
      {...props}
    >
      {children}
    </Component>
  );
}
