import React from 'react';
import clsx from 'clsx';
import { Text } from '@/@types/Text';
import { ActionTextVariant } from '@/components/typography/actions/ActionText';

export type BodyTextVariant = 'sm' | 'base' | 'xl' | 'lg';

interface BodyTextProps extends Text {
  variant: ActionTextVariant;
  theme?: 'light' | 'dark';
  isInline?: boolean;
}
interface Props extends Text {
  theme?: 'light' | 'dark';
  isInline?: boolean;
}

export const BodyTextXL = (props: Props) => <BodyText variant="xl" {...props} />;
export const BodyTextLg = (props: Props) => <BodyText variant="lg" {...props} />;
export const BodyTextBase = (props: Props) => <BodyText variant="base" {...props} />;
export const BodyTextSm = (props: Props) => <BodyText variant="sm" {...props} />;

export function BodyText({
  children,
  variant,
  isInline,
  className,
  theme = 'light',
  as: Component = 'p'
}: BodyTextProps) {
  return (
    <Component
      theme={theme}
      className={clsx(
        variant === 'xl' && 'text-xl font-normal tracking-inter-tighter',
        variant === 'lg' && 'text-lg font-normal tracking-inter-tight',
        variant === 'base' && `text-base font-normal  ${isInline ? 'leading-4' : 'leading-5'}`,
        variant === 'sm' &&
          `text-sm font-normal tracking-inter-wide ${isInline ? 'leading-3.5' : 'leading-4'}`,
        className
      )}
    >
      {children}
    </Component>
  );
}
